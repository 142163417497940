<template>
<div>
    <DollarHome />
</div>
</template>

<script>
import DollarHome from '../../components/dashboard/dollarAsset/DollarHome.vue'
export default {
    components: {
        DollarHome
    }
}
</script>

