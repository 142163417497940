<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
        </div>-->
        <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div> -->


<!-- <div class="mt-2"></div> -->
<div class="invest-container">
  
<div class="fixed-dollar-header">

<div class="row">

    <div class="col-md-10">
          <img
                      src="../../../assets/images/back1.png"
                      height="40"
                    v-if="windowWidth > 767"
                    class="point-cursor mt-2" @click="sendToMarketPlace">
        <h3 class="mt-4">{{dollarProduct.name}}</h3>
          <!-- <p>Imagine Africa</p> -->
          <div class="returns-div mt-4">
    <span style="color:#0094FF">7% - 15% annual returns</span>
        <span>Medium Risk</span>
         <img src="../../../assets/images/info.png"
                      height="20"
                      class="point-cursor"
                    >
        </div>
    
    </div>
    <div class="col-md-2" v-if="windowWidth > 767">
        <div style="min-height:60px; display:flex;align-items:center">

        </div>
        <button class="purchase-btn" @click="gotoInvest" v-if="token">

            Purchase <span style="font-size:16px">   <img
                      src="../../../assets/images/chevright.png"
                      height="15"
                 class="pl-2"
                    > </span>
        </button>
    </div>
</div>
</div>


<div class="dollar-asset-sub-div">

<div class="mb-3">
  <template v-if="chartLoader">
    <apexchart type="line" height="350" ref="chart" :options="returnOptions" :series="series"></apexchart>

  </template>



    <div>
      <div class="row">
    <div class="col-md-3 col-4 col-sm-3">
      <p class="mb-3" style="color:#828282">Price History</p>

      <div style="display:flex; justify-content:space-between" >
        <div style="flex-basis:20%; cursor:pointer;min-height:30px; align-items:center; 
justify-content: center;
display: flex;" class="text-center" :class="{'active-chat-btn':chartType == 'sixMonths'}" @click="getsixMonthsChart">
            <p >6M</p>
        </div>
        <div style="flex-basis:20%;  cursor:pointer;min-height:30px; align-items:center; 
justify-content: center;
display: flex;" class="text-center" :class="{'active-chat-btn':chartType == 'oneYear'}" @click="getOneYearChart"> 
            <p >1Y </p>
        </div>
      
     
      
      
      </div>
    </div>
    </div>
<div class="row mt-4">
  <div class="col-md-6">
    <div style="display:flex; border-radius:10px; background:#fff; min-height:50px; align-items:center; max-width:400px; padding:10px">
      <div style="flex-basis:70%">
      <p style="color:#4F4F4F">Opt out anytime and claim your profit</p>
      </div>
      <div class="text-right" style="flex-basis:30%">
         <img
                      src="../../../assets/images/info.png"
                      height="25"
                 class="pl-2"
                    > 
      </div>
      </div>  
    </div>
    </div>
    </div>
</div>
  <div class="row">
    <div class="col-md-8">
      <div class="mb-4 mt-4">
   <h6><b>About this fund  </b></h6>
    <p>{{dollarProduct.details}}
</p>

      </div>
      <!-- <div class="mb-4">
   <h6><b>How you earn  </b></h6>
    <p>Daily, the fund's unit price changes. These changes determine your returns. For example, if the price was N100 yesterday and N120 today, you have gained N20. However, there can be losses.
</p>

      </div>
      <div class="mb-4">
   <h6><b>Suitability </b></h6>
    <p>The Fund is targeted at High Net Worth Individuals (HNIs), Africans in the diaspora, and institutional investors (both local and foreign), who desire exposure to Dollar-denominated fixed income securities.
</p>

      </div>
      <div class="mb-4">
   <h6><b>Terms Of Use
 </b></h6>
    <p>This fund is provided by United Capital Asset Management. However, all transactions are aggregated by Cowrywise. As such, you can only redeem purchased units of this mutual fund directly through reQuid.
</p>

      </div>
      -->
    </div>
    <!-- <div class="col-md-4">
      <div class="detail-box mt-4">
        <div style="border-bottom: 1px solid #E0E0E0; padding:20px">
          <h6>FUND DETAILS</h6>
        </div>

        <div style="display:flex; justify-content:space-between; padding:20px; flex-wrap:wrap">
          <div style="flex-basis:50%" class="mb-4">
            <p style="color:#828282" class="pb-2">Fund Size</p>
            <p style=" font-size:14px"><b>$14.5m </b></p>
          </div>
          <div style="flex-basis:50%" class="mb-4">
            <p style="color:#828282" class="pb-2">Trustee</p>
            <p style=" font-size:14px"><b>United Capital Trustees</b></p>
          </div>
          <div style="flex-basis:50%" class="mb-4">
            <p style="color:#828282" class="pb-2">Custodian</p>
            <p style=" font-size:14px"><b>FCMB Limited</b></p>
          </div>
          <div style="flex-basis:50%" class="mb-4">
            <p style="color:#828282" class="pb-2">Payment Return Timeline</p>
            <p style=" font-size:14px"><b>Yearly Payment </b></p>
          </div>
          <div style="flex-basis:50%" class="mb-4">
            <p style="color:#828282" class="pb-2">Management Fee </p>
            <p style=" font-size:14px"><b>1.50%</b></p>
          </div>
     
          <div style="flex-basis:100%; justify-content:flex-end; display:flex" class="mb-3">
            <p style="color:#828282">See Prospectus  ></p>
          </div>
         
        </div>
      </div>

    </div> -->
  </div>
</div>

<div style="display: block; opacity: 1; z-index: 30; height: 55px; color: rgb(255, 255, 255) !important; min-width: 100%;  position: fixed; left: 0px; bottom: 20px; text-align: center;" v-if="windowWidth < 768"><button style="min-width: 50% !important; border-radius: 5px; box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 8px; min-height: 50px;margin:0 auto ; color:#fff; border:1px solid #0094FF; background:#0094FF" @click="gotoInvest" v-if="token"> Purchase <span style="font-size:16px">   <img
                      src="../../../assets/images/chevright.png"
                      height="15"
                 class="pl-2"
                    > </span></button></div>
</div>

      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";
import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
      chartType:'sixMonths',
      chartLoader:false,
      dollarProduct : {},
   options: {
        chart: {
          id: 'vuechart-example',
              zoom: {
          enabled: false,
              }
        },
        xaxis: {
          categories: []
        },
        //   title: {
        //   text: 'Price History',
        //   align: 'left'
        // },
      },
     
      series: [{
        name: 'eurobond',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }],
      SixMonthsDollarBondHistory:null,
      oneYearDollarBondHistory:null,
    }
  },
  methods: {
    sendToMarketPlace() {
      this.$router.push("/market");
    },
 
  
    gotoInvest() {
      this.$router.push({
        path: "/dollar/invest",
      });
    },

getOneYearChart(){

  //console.log(this.SixMonthsDollarBondHistory.title, 'hist')
  this.chartLoader = false
  this.chartType = 'oneYear'
this.series = [{
    data: this.oneYearDollarBondHistory.price
   }]

   let title = this.oneYearDollarBondHistory.title.filter((data)=>{

return data
   })

   this.options.xaxis.categories.push(...title)
  this.chartLoader = true
  
},
   
getsixMonthsChart(){

  //console.log(this.SixMonthsDollarBondHistory.title, 'hist')
  this.chartLoader = false
  this.chartType = 'sixMonths'
this.series = [{
    data: this.SixMonthsDollarBondHistory.price
   }]

   let title = this.SixMonthsDollarBondHistory.title.filter((data)=>{

return data
   })

   this.options.xaxis.categories.push(...title)
  this.chartLoader = true
  
},
   

  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper,
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
      // oneYearDollarBondHistory: state => state.market.oneYearDollarBondHistory,
      // SixMonthsDollarBondHistory: state => state.market.SixMonthsDollarBondHistory,
     
   
     
    }),
 returnOptions(){

   return this.options
 }
  },

  // beforeCreate(){

  // this.$store.dispatch("market/getEuroBondHistory");
  // },

  mounted() {

    let dollarFromLocalStorage = localStorage.getItem('dollarProduct')

    this.dollarProduct =JSON.parse(dollarFromLocalStorage) 
    let heading = {
          main: this.tran.euro_bond
    };
   
  this.$store.commit("market/savePageHeading", heading);

let euroBondData = localStorage.getItem('euroBond')


euroBondData = JSON.parse(euroBondData)

this.SixMonthsDollarBondHistory = euroBondData.sixMonths
this.oneYearDollarBondHistory = euroBondData.oneYear

  //console.log(this.SixMonthsDollarBondHistory.title, 'hist')
this.series = [{
    data: this.SixMonthsDollarBondHistory.price
   }]

   let title = this.SixMonthsDollarBondHistory.title.filter((data)=>{

return data
   })
   this.options.xaxis.categories.push(...title)

   this.chartLoader = true

 

  }
};
</script>

<style scoped>

.active-chat-btn{
background:#fff; border-radius:10px;
min-width:30px;
}
.purchase-btn{

    background:#0094FF;
      border: 1px solid #0094FF; 
    border-radius: 5px;
    padding: 5px;
    min-height: 45px;
    color: #fff;
    min-width: 100%;
    margin-bottom: 10px;
}
.fixed-dollar-header{

    padding: 20px 100px;
    border-bottom: 1px solid rgb(235 235 235);
    position: sticky !important;
    top: 60px;
    min-width: 90%;
    z-index: 20;
    background: #F5F5F5;
}

.dollar-asset-sub-div{
    padding: 20px 100px;
}

.detail-box{
border-radius: 10px;
  min-height: 200px;
  background:#fff;
}

.returns-div{

  display:flex; justify-content:space-between; max-width:40%;
}

@media (max-width: 1500px) {
  .invest-container {
    zoom: 80%;

  }
}


@media (max-width: 767px) {
    .invest-container {
    zoom: 100%;
    
  }
 .fixed-dollar-header{

    padding: 10px 10px;
    border-bottom: 1px solid #f4f4f4;
    position: relative !important;
    top: 0px;
    min-width: 100%;
 
}
.dollar-asset-sub-div{
    position: relative !important;
  
    padding: 10px 10px;
}

.returns-div{
max-width:100%;
}
}
</style>
